import { graphql } from "gatsby"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import FindDreamJob from "../components/careers/finddreamjob"
import DarkHeader from "../components/layout/darkheader"
import Layout from "../components/layout/layout"
import MentoringPoss from "../components/mentoring/mentoringposs"
import Seo from "../components/seo"

import "./mainpage.scss"
import "./mentoring.scss"
const MentoringPage = () => {
    const { i18n } = useI18next()
    const { t } = useTranslation()
    const pageTitle = t("title", { ns: 'mentoring' })
    const pageDescription = t("We Promote Employee Excellence", { ns: 'mentoring' })
    const lang = 'EN'
    const meta = []
    const breadcrumbMap = []
    const title2 = t("We Promote Employee Excellence", { ns: 'mentoring' });
    const sectionClassName = "section-mentoring-header";

    return (
        <>
            <Layout breadcrumbMap={breadcrumbMap} headerTransparent={true} lang={i18n.resolvedLanguage}>
                <div className="max-container-pages">
                    <Seo title={pageTitle} lang={i18n.resolvedLanguage} description={pageDescription} meta={meta} />
                    <DarkHeader
                        title1={pageTitle}
                        title2={title2}
                        sectionClassName={sectionClassName}
                        //onAccept={() => handleShow('xxl-down')}
                        callButton={false}
                    >
                        <p>
                            {t("Our industry is a fast-paced one")}
                        </p>
                    </DarkHeader>
                    <MentoringPoss></MentoringPoss>
                    <FindDreamJob></FindDreamJob>

                </div>
            </Layout>
        </>
    )
}

export default MentoringPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "mentoring"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
