import "./mentoringposs.scss"

import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"

const MentoringPoss = () => {
    const { t } = useTranslation()
    return (
        <>
            <section id="goContent" className="section-mentoring-poss">
                <Container fluid="xl">
                    <Row className="flex-reverse">
                        <Col className="col-img" md={6}>
                            <StaticImage
                                className="d-md-none"
                                layout="fullWidth"
                                alt="Mentorship Possibilities"
                                src="../../images/mentorship/mentorship-possibilities.webp"
                                formats={["auto", "webp", "avif"]}
                            />
                        </Col>
                        <Col className="col-text" md={6}>
                            <h3 className="header-square green md-large">
                                {t("At Blue Trail, we recognize how critical it is to keep our personnel up-to-date")}
                            </h3>
                        </Col>
                    </Row>
                    <Row className="justify-content-center flex-gap row-possibilities">
                        <Col className="col-possibilities" md={6}>
                            <h4 className="header-bg-number one">{t("Training and certifications")}</h4>
                            <p>
                                {t("Our extensive curriculum combines proprietary and third-party training courses")}
                            </p>
                        </Col>
                        <Col className="col-possibilities" md={6}>
                            <h4 className="header-bg-number two">{t("Lvl-up campaigns")}</h4>
                            <p>
                                {t("We believe that the better our employees understand the development process")}
                            </p>
                        </Col>
                        <Col className="col-possibilities" md={6}>
                            <h4 className="header-bg-number three">{t("Mentoring")}</h4>
                            <p>
                                {t(" We encourage our more senior engineers to mentor the less experienced ones to help them perform their day-to-day work more efficiently")}
                            </p>
                        </Col>
                        <Col className="col-possibilities" md={6}>
                            <h4 className="header-bg-number four">{t("Coaching")}</h4>
                            <p>
                                {t("We use a series of techniques")}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default MentoringPoss
